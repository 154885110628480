@import "../../index.scss";

.card {
  margin: 15px;
}
.panels .card:nth-child(2) {
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.panels .card:nth-child(3) {
  border-left: 1px solid rgba(0, 0, 0, 0.125) !important;
}

.panels .card .card-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.panels .card img {
  height: 196px;
  width: 348px;
  object-fit: cover;
}

@media (max-width: 700px) {
  .card-group {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
