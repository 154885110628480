@import "../../index.scss";

form label {
  width: 300px;
}
form input {
  border: 0px;
}
form select {
  border: 0px;
}
form .input {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

form input {
  border: 1px solid $primary;
  padding: 5px;
  margin: 2px;
  width: 100%;
  border-radius: 5px;
}

form select {
  width: 100%;
}

.note-wrapper {
  padding: 0px;
  border-radius: 10px;
  margin: 10px 0px 20px;
  color: black;
  height: auto;
  display: block;
  transition: height 0.3s, padding 0.3s, opacity 0.3s;
  font-weight: bold;
  font-size: medium;
}

.note-wrapper.hidden {
  display: none;
}

.done-wrapper {
  display: none;
  background-color: $green;
  padding: 0px;
  border-radius: 10px;
  margin: 20px 0px;
  color: white;
  height: 0;
  transition: height 0.3s, padding 0.3s, opacity 0.3s;
}

.done-wrapper.done {
  display: block;
  height: auto;
  padding: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.items div {
  padding: 20px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.4s ease-in-out;
}
.items div:hover {
  background-color: rgb(230, 230, 230);
}

.selected-items {
  background-color: $lightGray;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
}

.selected-items h4 {
  color: $primary;
}

.selected-items div {
  background-color: $light-blue;
  border-radius: 10px;
  padding: 8px;
  color: white;
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.items-container {
  display: flex;
  flex-direction: row;
}

.items-container .items {
  flex-grow: 1;
}

.items-container .items .selected::before {
  content: "✅";
}
.items-container .items .selected {
  background-color: rgb(150, 209, 150);
}

.selection-controls {
  transition: width 0.4s ease-in-out;
  padding-left: 20px;
}

.selection-controls.inactive {
  width: 0px;
  opacity: 0;
}

.selection-controls.active {
  opacity: 1;
  width: 300px;
}
