@import "../../index.scss";

#bottom-shape-bottom {
  margin-bottom: -50px;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  width: 600px;
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
}

#overlay-small {
  height: 200px;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 74, 135, 0.87) 100%
  );
  opacity: 0.47;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
  align-items: center;
}

.details-component-wrapper .header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.details-wrapper .title {
  background-color: $info;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
  border: 0px;
  background-color: $menu;
}
.details-wrapper .title h4 {
  color: white;
}

.details-wrapper .details-row {
  background-color: $lightGray;
  padding: 0px 20px;
}

.details-wrapper .details-row:last-child {
  margin-bottom: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.tags {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
}

.tag-input-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.tag-input-wrapper input {
  background-color: $gray;
  color: $primary;
  border: 0;
  border-radius: 5px;
  padding: 10px;
}

.tag-input-wrapper button {
  border: 0;
  background-color: $green;
  color: white;
}

.checkbox-toggle {
  background-color: $lightGray;
  border-radius: 50px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.checkbox-toggle span {
  margin-right: 10px;
}

.div-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.div-row .value-error {
  color: $errorColor;
}

.div-row .value-warning {
  color: $errorColor;
}

.div-row .value-success {
  color: green;
}
