@import "../../index.scss";

.printable-object-qr {
    padding: 30px;
}
.printable-object-qr h1 {
    font-size: 60pt;
    color: $primary;
    font-family: "gotham", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
}

.printable-object-qr h1 span {
    color: $secondary;
    font-size: 60pt;
}

.printable-object-qr .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.printable-object-qr h2 {
    color: $primary;
}

.printable-object-qr span {
    font-family: "gotham", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    font-size: 20pt;
    margin-bottom: 100px;
}

.printable-object-qr .content {
    display: flex;
    flex-direction: column;
}

.printable-object-qr .content canvas{
    justify-self: center;
    align-self: center;
}
.catalog-item {
    background-color: $primary;
    padding: 10px;
    border-radius: 10px;
    color: white;
    margin: 5px;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.catalog-item a h1 {
    font-size: 17pt;
    color: white;
}