@import url("https://fonts.googleapis.com/css2?family=Encode+Sans+Semi+Expanded:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~bootstrap/scss/bootstrap";

$body-bg: white;

$primary: #00456d;
$secondary: #00adc3;
$success: #28a745;
$info: #375a6e;
$warning: #ffc107;
$danger: #dc3545;
$light: #9aaebd;
$dark: #252423;

$lightGreen: #81c784;
$purple: #673ab7;
$orange: #ef6c00;
$darkGreen: #1b5e20;
$teal: #009688;
$blueGrey: #607d8b;

$primary-low: #636a6e;
$primary-mid: #375a6e;
$primary-high: #164d6e;
$light-blue: #00aecb;

$secondary-low: #aec0c2;
$secondary-mid: #61b6c2;
$secondary-high: #27b0c2;

$menu: #9aaebd;
$footer: #f6f6f6;
$errorColor: #f98484;
$gray: #f0f2f5;
$lightGray: #f2f2f2;
$mobileTabColor: #eeeeee;

$buttonBlue: #00aecb;
.shadow {
  -webkit-box-shadow: -1px 1px 21px -6px rgba(189, 189, 189, 1);
  -moz-box-shadow: -1px 1px 21px -6px rgba(189, 189, 189, 1);
  box-shadow: -1px 1px 21px -6px rgba(189, 189, 189, 1);
}
.no-padding {
  padding: 0;
}

.padding {
  padding: 20px;
}

.rounded-container {
  margin: 15px;
  border-radius: 10px;
  background-color: white;
  padding: 20px;
  margin-bottom: 20px;
}
.rounded-container h4 {
  color: $primary;
}
.rounded-container h3 {
  color: $primary;
}

.rounded-button.blue {
  color: #ffffff;
  border-color: #00aecb;
  background-color: #00aecb;
}

.rounded-button.red {
  color: #ffffff;
  border-color: $errorColor;
  background-color: $errorColor;
}

.rounded-button.dark-gray {
  color: #ffffff;
  border-color: $primary-low;
  background-color: $primary-low;
}

.rounded-button.darkGreen {
  color: #ffffff;
  border-color: $darkGreen;
  background-color: $darkGreen;
}

.rounded-button.purple {
  color: #ffffff;
  border-color: $purple;
  background-color: $purple;
}
.rounded-button:active {
  outline: none;
}
.rounded-button {
  outline: none;
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19f947d6: #fff;
  --e-global-color-7f49d1fd: #000;
  --e-global-color-4b783e31: RGBA(0, 50, 91, 0.86);
  --e-global-color-3e6f5453: RGBA(0, 74, 135, 0.87);
  --e-global-color-7d1885f5: #007c5a;
  --e-global-color-2035e11: #00aecb;
  --e-global-color-443c8b22: #ff6418;
  --e-global-color-2cb817fc: #7f2347;
  --e-global-color-7ff4823e: RGBA(255, 100, 24, 0.89);
  --e-global-color-4c117289: #00325b;
  --e-global-color-37197698: #f9f9f9;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  hyphens: manual;
  border: 0;
  font-style: inherit;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  transition: all 0.3s;
  box-shadow: none;
  text-decoration: none;
  border-style: solid;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
  border-bottom-width: 0;
  border-color: #00aecb;
  font-family: inherit;
  font-weight: inherit;
  line-height: 1;
  width: auto;
  border-radius: 50px;
  padding-top: 15px;
  padding-right: 40px;
  padding-bottom: 15px;
  padding-left: 40px;
  font-size: 1rem;
  fill: #0984e3;
  color: #0984e3;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.domain-image {
  width: 550px;
  height: 128px;
  margin: 10px;
  object-fit: cover;
}

.domain-image.small {
  width: 300px;
  height: 70px;
  margin: 10px;
  object-fit: cover;
}

.domain-image.large {
  width: 700px;
  height: 162px;
  margin: 10px;
  object-fit: cover;
}

img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 700px) {
  .rounded-container {
    margin: 20px 10px;
  }
}

a {
  color: #3a3a3a;
  font-size: 14pt;
}

body {
  margin: 0;
  line-height: 1.85714285714286;
  font-family: "Montserrat", sans-serif;
  font-size: 14pt;
  color: #3a3a3a;
  background-color: white;
}

h1 {
  font-family: "Encode Sans Semi Expanded", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 3.75rem;
}

h4 {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19f947d6: #fff;
  --e-global-color-7f49d1fd: #000;
  --e-global-color-4b783e31: RGBA(0, 50, 91, 0.86);
  --e-global-color-3e6f5453: RGBA(0, 74, 135, 0.87);
  --e-global-color-7d1885f5: #007c5a;
  --e-global-color-2035e11: #00aecb;
  --e-global-color-443c8b22: #ff6418;
  --e-global-color-2cb817fc: #7f2347;
  --e-global-color-7ff4823e: RGBA(255, 100, 24, 0.89);
  --e-global-color-4c117289: #00325b;
  --e-global-color-37197698: #f9f9f9;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  hyphens: manual;
  border: 0;
  font-style: inherit;
  outline: 0;
  vertical-align: baseline;
  clear: both;
  box-sizing: border-box;
  padding: 0;
  line-height: 1.5;
  font-size: 1.125rem;
  font-weight: 300;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  text-transform: none;
  margin: 0;
  color: #ffffff;
}

h2 {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19f947d6: #fff;
  --e-global-color-7f49d1fd: #000;
  --e-global-color-4b783e31: RGBA(0, 50, 91, 0.86);
  --e-global-color-3e6f5453: RGBA(0, 74, 135, 0.87);
  --e-global-color-7d1885f5: #007c5a;
  --e-global-color-2035e11: #00aecb;
  --e-global-color-443c8b22: #ff6418;
  --e-global-color-2cb817fc: #7f2347;
  --e-global-color-7ff4823e: RGBA(255, 100, 24, 0.89);
  --e-global-color-4c117289: #00325b;
  --e-global-color-37197698: #f9f9f9;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  hyphens: manual;
  text-align: left;
  border: 0;
  font-style: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 2.1875rem;
  color: #000000;
  font-weight: 400;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  line-height: 1.2;
  text-transform: none;
}

h3 {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19f947d6: #fff;
  --e-global-color-7f49d1fd: #000;
  --e-global-color-4b783e31: RGBA(0, 50, 91, 0.86);
  --e-global-color-3e6f5453: RGBA(0, 74, 135, 0.87);
  --e-global-color-7d1885f5: #007c5a;
  --e-global-color-2035e11: #00aecb;
  --e-global-color-443c8b22: #ff6418;
  --e-global-color-2cb817fc: #7f2347;
  --e-global-color-7ff4823e: RGBA(255, 100, 24, 0.89);
  --e-global-color-4c117289: #00325b;
  --e-global-color-37197698: #f9f9f9;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  hyphens: manual;
  border: 0;
  font-style: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  box-sizing: border-box;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #000000;
  font-weight: 300;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  text-transform: none;
}

h2 {
  hyphens: manual;
  text-align: left;
  border: 0;
  font-style: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 2.1875rem;
  color: #000000;
  font-weight: 400;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  line-height: 1.2;
  text-transform: none;
}

h3 {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  hyphens: manual;
  border: 0;
  font-style: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  clear: both;
  box-sizing: border-box;
  line-height: 1.4;
  margin-bottom: 20px;
  font-size: 1.25rem;
  color: #000000;
  font-weight: 400;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  text-transform: none;
}

.full-width-controls {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.full-width-controls .rounded-button-wrapper {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Collapsible {
  width: 100%;
}

.container h1 {
  font-family: "Encode Sans Semi Expanded", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.75rem;
  color: $primary;
}

.highlight h1 {
  font-family: "Encode Sans Semi Expanded", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  font-size: 3.75rem;
  color: white;
}

@media (max-width: 700px) {
  .highlight h1 {
    font-size: 1.75rem;
  }
}

.wide-modal {
  width: 40vw;
  max-width: 50vw;
}

.modal-footer button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
