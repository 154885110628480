@import "../../index.scss";

.table-controls {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 0px 10px 0px;
}

.table-controls > * {
  margin-left: 10px;
}

.pagination {
  background-color: $menu;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px !important;
  color: white;
}

.pagination .buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pagination .info {
  padding: 10px;
}

.pagination .buttons button {
  border: 0;
  background-color: $primary;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;
  min-width: 50px;
  outline: none;
}

.pagination .buttons button:disabled {
  background-color: #c0cdd6;
}

.pagination .pages select {
  background-color: #c0cdd6;
  color: white;
  border: 0;
  border-radius: 10px;
  padding: 14px 10px;
  outline: none;
}

.table tr th {
  border: 0;
  background-color: $menu;
  font-weight: 200;
}

.table th {
  background-color: $menu;
  color: white;
  border: 0;
}
.table th:first-of-type {
  border-top-left-radius: 10px;
}

.table th:last-of-type {
  border-top-right-radius: 10px;
}

.form-group .rbt input {
  background-color: white;
  border-radius: 10px;
  min-height: 50px;
}

.table tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px;
  border: 0px;
}

.table tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px;
}

.table-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
