@import "../../index.scss";

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
.react-daterange-picker__wrapper {
  background-color: $buttonBlue;
  border: 0;
  padding: 10px;
  border-radius: 30px;
  color: white;
}

.react-daterange-picker input {
  color: white;
}

.react-daterange-picker .react-daterange-picker__range-divider {
  color: gray;
}

.lines-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.lines-wrapper .col .header h1 {
  margin-right: 30px;
}
.lines-wrapper .col .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.lines-wrapper .day {
  background-color: $lightGray;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.lines-wrapper .line {
  padding: 10px;
  border-radius: 10px;
  transition: all 0.2s ease;
}

.lines-wrapper .line:nth-child(odd) {
  background-color: $footer;
}

.lines-wrapper .line:hover {
  background-color: gray;
  color: white;
}

.lines-wrapper .line .item-line .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.filter-select-wrapper {
  background-color: $light;
  color: white;
  padding: 10px;
  border-radius: 10px;
  margin-top: 20px;
}

.filter-select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
