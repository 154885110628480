@import "./index.scss";

$footerHeight: 500px;
$height: 700px;

#main {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: $height;
}

#main h1 {
  color: #fff;
}

#overlay {
  height: $height;
  background-color: transparent;
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.86) 0%,
    rgba(0, 74, 135, 0.87) 100%
  );
  opacity: 0.47;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: space-between;
  align-items: center;
}

#main-header-content {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  height: $height;
  opacity: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

#main-header-content .highlight {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

#main-header-content .image {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}

#main-header-content .jumbotron {
  padding-top: 100px;
  background: none;
}

#bottom-shape {
  transform: rotate(180deg);
  width: 100%;
  margin-bottom: -1px;
}

#bottom-shape svg {
  height: 100px;
  width: 100%;
}

#bottom-shape svg path {
  fill: $gray;
}

.main-image {
  width: 40%;
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.contact .details a {  
  text-align: left;
  hyphens: manual;
  font-family: "Encode Sans Semi Expanded", sans-serif;
  text-transform: none;
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  background-color: transparent;
  transition: all 0.2s linear;
  box-sizing: border-box;
  box-shadow: none;
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}

.contact .details p {
  font-family: "Montserrat", sans-serif;
  color: #3a3a3a;
  text-align: left;
  hyphens: manual;
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  padding: 0;
  line-height: 1;
  margin: 0;
  font-size: 12px;
}

.contact .details span {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  line-height: 1.85714285714286;
  font-family: "Montserrat", sans-serif;
  hyphens: manual;
  color: #00aecb;
  text-align: left;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
}

.contact img {
  margin-right: 20px;
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  line-height: 1.85714285714286;
  font-family: "Montserrat", sans-serif;
  --grid-column-gap: 30px;
  --grid-row-gap: 35px;
  hyphens: manual;
  text-align: right;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  color: #00aecb;
  width: 75px;
  transition-property: transform;
  box-sizing: border-box;
  height: auto;
  border: none;
  box-shadow: none;
  vertical-align: middle;
  display: inline-block;
  transition-duration: 1s;
  border-radius: 100px 100px 100px 100px;
}

footer {
  margin-top: 100px;
  height: $footerHeight;
  background-color: #00325b;
  background-image: url(https://www.navet.se/wp-content/uploads/2019/12/rekrytering-projektledare-2.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

footer #overlay {
  padding-top: 50px;
  height: $footerHeight;
  background-color: transparent;
  background-image: linear-gradient(
    0deg,
    #00325b 40%,
    rgba(0, 50, 91, 0.91) 100%
  );
  opacity: 1;
  transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
}

footer #footer-content {
  width: 100%;
  height: $footerHeight;
  opacity: 1;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

footer #footer-content p {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  font-family: "Montserrat", sans-serif;
  --e-global-color-primary: #6ec1e4;
  --e-global-color-secondary: #54595f;
  --e-global-color-text: #7a7a7a;
  --e-global-color-accent: #61ce70;
  --e-global-color-19f947d6: #fff;
  --e-global-color-7f49d1fd: #000;
  --e-global-color-4b783e31: RGBA(0, 50, 91, 0.86);
  --e-global-color-3e6f5453: RGBA(0, 74, 135, 0.87);
  --e-global-color-7d1885f5: #007c5a;
  --e-global-color-2035e11: #00aecb;
  --e-global-color-443c8b22: #ff6418;
  --e-global-color-2cb817fc: #7f2347;
  --e-global-color-7ff4823e: RGBA(255, 100, 24, 0.89);
  --e-global-color-4c117289: #00325b;
  --e-global-color-37197698: #f9f9f9;
  --e-global-typography-primary-font-family: "Roboto";
  --e-global-typography-primary-font-weight: 600;
  --e-global-typography-secondary-font-family: "Roboto Slab";
  --e-global-typography-secondary-font-weight: 400;
  --e-global-typography-text-font-family: "Roboto";
  --e-global-typography-text-font-weight: 400;
  --e-global-typography-accent-font-family: "Roboto";
  --e-global-typography-accent-font-weight: 500;
  hyphens: manual;
  text-align: left;
  border: 0;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  margin: 0;
  color: #ffffff;
  font-size: 12px;
  line-height: 1.5em;
  margin-bottom: 20px;
}

#root {
  background-color: $gray;
}

footer #footer-content h2 {
  color: #fff;
}

.main-content {
  padding-top: 0px;
  background-color: $gray;
}
