@import "../../index.scss";

.invitation-background {
  background-image: url("./tannus.jpg");
}

.success {
  background-color: $green;
  padding: 20px;
  border-radius: 10px;
  color: white;
}

.domain img {
  height: 100px;
}

.deactivated {
  background-color: $errorColor;
  color: white;
  padding: 10px;
  border-radius: 5px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
