@import "../../index.scss";

.tile {
  flex-direction: row;
}

.tile .multi-value {
  margin-left: 100px;
}

.tile .headline {
  color: $primary;
}

.quick-rent-info-wrapper {
  background-image: url("./bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.table tr td .description {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.table tr td .description img {
  width: 70px;
  margin-right: 10px;
}

span.tag {
  background-color: $primary;
  color: white;
  margin-right: 6px;
  margin-bottom: 5px;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.table tr td .returndate {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table tr td .returndate .expired {
  color: $errorColor;
}

.rentalline {
  padding: 10px;
  border-radius: 10px;
}
.rentalline:nth-child(even) {
  background-color: $lightGray;
}

.rentalline .details-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.rentalline .details-row span:first-of-type {
  font-weight: 500;
  margin-right: 20px;
}

.rentalline .details-row span:last-of-type {
  font-weight: 200;
}

.selected-rows-controls {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
}

.project-overview h2 {
  color: $primary;
  font-size: 16pt;
}
.project-overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all .2s;
}

.project-overview.last {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.project-overview:hover {
  background-color: $lightGray;
}

.projects .link a {
  text-decoration: none;
}

.projects .link h2:hover {
  text-decoration: underline;
}
