@import "../../index.scss";

.buttons {
  display: flex;
  flex-direction: row;
}

.buttons button{
  margin-right: 20px;
}

.selectable .item {
  border: 2px solid #fff;
  transition: border 0.2s ease-in;
}

.selectable .item.selected {
  border: 2px solid $green;
}

.domainheader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.remove-buttons {
  display: flex;
  flex-direction: row;
}
.remove-buttons button {
  margin-right: 20px;
}
.heartbeats {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.heartbeat {
  padding: 10px 5px;
  background-color: $secondary-low;
  border-radius: 10px;
  margin: 5px;
}

.online-badge {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}
