@import "../../index.scss";

.main-background {
  background-image: url("./NavetEdge_bild1-scaled.jpg");
}

ul {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  line-height: 1.85714285714286;
  font-family: "Montserrat", sans-serif;
  color: #3a3a3a;
  hyphens: manual;
  text-align: left;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
  vertical-align: baseline;
  list-style: disc;
  box-sizing: border-box;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li {
  --page-title-display: block;
  -webkit-font-smoothing: antialiased;
  line-height: 1.85714285714286;
  font-family: "Montserrat", sans-serif;
  color: #3a3a3a;
  hyphens: manual;
  list-style: disc;
  list-style-type: none;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  outline: 0;
  vertical-align: baseline;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  justify-content: flex-start;
  text-align: left;
  padding-bottom: calc(50px / 2);
}

ul.stores li img.android {
  width: 220px;
}
ul.stores li img.ios {
  width: 450px;
}

ul.stores li {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

img.chevron {
  margin-right: 10px;
}

.img-points div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
