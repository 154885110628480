@import "../../index.scss";

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2.5rem;
  position: absolute !important;
  z-index: 1;
  width: 100%;
}

.navbar-sticky {
  background: #fff;
  position: fixed !important;
  top: 0;
  left: 0;
  box-shadow: 2px 2px 2px rgb(185, 185, 185);
  animation: moveDown 0.5s ease-in-out;
}

.navbar--logo {
  width: 4rem;
  height: 4rem;
  margin-right: 0.5rem;
  animation: rotate 0.7s ease-in-out 0.5s;
}

.navbar--logo-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

@keyframes moveDown {
  from {
    transform: translateY(-5rem);
  }
  to {
    transform: translateY(0rem);
  }
}

@keyframes rotate {
  0% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0rem);
  }
}
